/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-bkg: 50deg 30% 96.1%;
        --color-content: 240deg 4.3% 22.5%;
        --color-accent1: 14.2deg 82.5% 47.1%;
        --color-accent2: 204deg 2% 48%;
        --color-cBkg: 230deg 30% 96.1%;
        --color-cContent: 60 4.3% 22.5%;
        --color-cAccent1: 194.2deg 82.5% 47.1%;
        --color-cAccent2: 24deg 2% 48%;
    }
    :root[class="dark"] {
        --color-bkg: 240deg 2.4% 16.5%;
        --color-content: 0deg 0% 99.2%;
        --color-accent1: 28.3deg 75.4% 58.6%;
        --color-accent2: 195deg 4.8% 51%;
        --color-cBkg: 60deg 2.4% 16.5%;
        --color-cContent: 0deg 0% 94.1%;
        --color-cAccent1: 208.3deg 75.4% 58.6%;
        --color-cAccent2: 15deg 4.8% 51%;
    }
}

@layer components {
    .max-container {
        max-width: 1440px;
        margin: 0 auto;
    }

    .input {
        @apply sm:flex-1 max-sm:w-full text-base leading-normal text-slate-gray  pl-5 max-sm:p-5 outline-none sm:border-none border max-sm:border-slate-gray max-sm:rounded-full;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

@layer utilities {
    .padding {
        @apply sm:px-16 px-8 sm:py-24 py-12;
    }

    .padding-x {
        @apply sm:px-16 px-8;
    }

    .padding-y {
        @apply sm:py-24 py-12;
    }

    .padding-l {
        @apply sm:pl-16 pl-8;
    }

    .padding-r {
        @apply sm:pr-16 pr-8;
    }

    .padding-t {
        @apply sm:pt-24 pt-12;
    }

    .padding-b {
        @apply sm:pb-24 pb-12;
    }

    .info-text {
        @apply font-montserrat text-slate-gray text-lg leading-7;
    }
}
